import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import ChevronRight from '../../../images/storybook-images/panelsV2/footer/chevron-right-black.svg'
import { trackSignUp } from '../../../lib/ga4'
import klavyioSignUp from '../../../lib/klavyio'
import Toggle from '../../Cartv2/Toggle'
import Video from '../Video'

const ErrorMsg = styled.div`
  font-weight: 600;
  color: rgb(var(--terracota));
  margin: 10px;
`
const SignupContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 16px;
  }
`
const EmailButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;
  @media (max-width: 1025px) {
    flex-direction: column;
  }
`
const TextInputContainer = styled.div`
  background-color: #fff;
  padding: 8px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-grow: 1;
  // border-radius: 50px;
  margin-right: 20px;
  @media (max-width: 1025px) {
    margin-right: 0;
  }
`
const PersonalDetailsContainer = styled.div`
  display: flex;
  margin: 10px 0;
`
const TextInput = styled.input`
  font-size: 16px;
  font-family: Objectivity;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  background-color: transparent;
  border: none;
  outline: none;
  width: calc(100% - 16px);
  margin-left: 8px;
  color: black;
  font-weight: 500;
  height: 48px;
  padding: 0 16px;
  @media (max-width: 1025px) {
    text-align: left;
    font-size: 18px;
  }

  ::placeholder {
    color: black; // Set the placeholder text color to white
    opacity: 0.5; // You can adjust the opacity if needed
  }
`
const SignTransparentButton = styled.div`
  border-radius: 27px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  background: left;
  height: 48px;
  padding: 0 16px;
  outline: none;
  color: #000000;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (max-width: 1025px) {
    color: white;
  }
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(grey, 0.2);
    color: black;
    border-color: white;
  }
  &:hover {
    background-color: rgba(grey, 1);
    color: black;
    border-color: white;
  }
`
const HeaderCopyHeading = styled.div`
  /* width: 558px;
  height: 76px; */
  object-fit: contain;
  font-family: Objectivity;
  font-weight: ${(props) => (props.large ? `400` : '600')};
  ${(props) => (props.large ? `` : 'text-transform: uppercase;')}
  font-size: ${(props) => (props.large ? `24px;` : '14px')};
  line-height: 1.15;
  ${(props) => (props.color ? `color: ${props.color};` : '')}

  text-align: left;

  @media (min-width : 1026px) {
    ${(props) => (props.textPosition === 'custom-top' ? `text-align: start;` : '')};
    ${(props) => (props.textPosition === 'custom-top' ? `max-width: 500px;line-height: 1.1;` : '')};
    // margin-left: 5px;
  }
  @media (max-width : 1025px) {
    /* width: 392px;
    height: 54px; */
    font-size: ${(props) => props.hideCopy ? '24px;' : '26px;'};
    font-size: ${(props) => props.HeaderFont !== 'Objectivity' && '36px;'};

    letter-spacing: -1.04px;
    width: 100%;
    font-size: ${(props) => (props.large ? `18px;` : '14px')};
  }
  @media (max-width: 768px) {
    /* width: 261px;
    height: 55px; */
    margin: ${(props) => props.hide ? '20px;' : '0px;'};
    line-height: 1.8;
    letter-spacing: -0.24px;
    text-align: left;
    width: 100%;

    ${(props) => (props.large ? `margin-bottom: 48px;` : '')};
  }
`
const HeaderCopySubHeading = styled.div`
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.21px;
  padding: 20px 0;
  
  ${(props) => (props.color ? `color: ${props.color};` : '')}

  @media (max-width : 1025px) {
    font-size: ${(props) => (props.BodyFontSize === 75 ? `64px;` : '18px;')}
    line-height: 1.1;
    letter-spacing: -0.16px;
  }
  @media (max-width: 768px) {
    opacity: 0.85;

    font-size: ${(props) => (props.BodyFontSize === 75 ? `64px;` : '14px;')}
    letter-spacing: -0.12px;
    line-height: 1.4;

    text-align: left;
    ${(props) => (props.hideCopy ? `display: none;` : '')};

  }

  @media (min-width : 1026px) {
    ${(props) => (props.textPosition === 'custom-top' ? `text-align: start;` : 'text-align: left;')}
    ${(props) => (props.TextAlign ? `text-align: ${props.TextAlign}` : 'text-align: left;')};
  }

  & ul {
    list-style-type: initial;
    margin: 0;
    padding: 0 0 0 16px;
  }

  & li {
    list-style: initial;
  }
`
const SectionContainerTop = styled.div`
  background-color: ${(props) => props.color};
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  ${(props) =>
    props.HeightPercentage
      ? `height: calc(${props.HeightPercentage}vh - 96px);`
      : 'height: calc(60vh - 96px);'}
  @media (max-width: 768px) {
    ${(props) => (props.mobilePadding ? 'padding: 32px 0px;' : '')};
    height: 100%;
    flex-direction: column-reverse;
  }
  @media (min-width: 1024px) {
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
    ${(props) => (props.negativeMargin ? 'height: calc(75vh - 126px);' : '')}
    ${(props) => (props.maxWidth ? 'max-width: 1200px; margin: 0 auto;' : '')}
  }

  ol {
    margin: 0px;
    padding: 5%;
  }
  li {
    list-style: decimal;
  }
`
const CopyWidthContainer = styled.div`
  text-align: left;
  @media (min-width: 1025px) {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    padding: 32px;
  }
  ${(props) =>
    props.elements === true
      ? `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    `
      : ''}
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 16px;
  }
`
const LogoImage = styled(GatsbyImage)`
  height: 70px;
  width: 100%;
`
const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
`
const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`
const HeaderVideo = styled(Video)`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : '')}
  }
`
const SectionCopy = styled.div`
  width: ${(props) => (props.image ? '60%;' : '40%;')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  background-color: rgb(var(--${(props) => props.color}));

  @media (min-width: 1026px) {
    ${({ school }) => school && `align-items: flex-start; text-align: left; padding-left: 10%;`}
    ${(props) => (props.textPosition === 'custom-top' ? `justify-content: start;` : 'justify-content: center;')}
  }
  @media (max-width: 1025px) {
    width: 100%;

    text-align: left;
    z-index: 2;
    ${(props) => (props.left ? 'align-items: flex-start;' : '')};
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) =>
    props.center ? 'text-align: center; align-items: center;justify-content: center;' : ''}
    ${({ trunk }) => trunk && `align-items: center;justify-content: center; text-align: center; `}
    ${(props) => (props.mobileAbsolute ? 'position: absolute; top: 22px; padding-top: 8px;' : '')}
  }
  @media (max-width: 768px) {
    ${(props) => props.hideCopy && 'height : 15vh;'}
    ${(props) => props.hide && 'display: none;'}
    height: ${(props) => (props.mHeight ? `${props.mHeight}vh;` : '')};    
  }
  @media (max-width: 320px) {
    ${(props) => props.hideCopy && 'height : 15vh;'}
  }
`
const ToggleContainer = styled.div`
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "Text Button ."
      "Text . ."
      ". . ."; 

    & .Text { grid-area: Text; }
    & .Button { grid-area: Button; }
`

const SignupForm = ({ data, onSubmit }) => {
  const theme = {
    Background: '#F7F6F4',
    Text: '#282828'
  }
  const Logo = (data?.Logo || [])[0]
  let [email, setEmail] = useState('')
  let [firstName, setFirstName] = useState('')
  let [lastName, setLastName] = useState('')
  let [phoneNo, setPhoneNo] = useState('')

  let [sent, setSent] = useState(false)
  let [emailValid, setEmailValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  let [consent, setConsent] = useState(true)

  const handleChange = (e) => {
    setEmail(e.target.value)
    validateEmail(e.target.value)
  }

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value)
  }

  const handleChangeLastName = (e) => {
    setLastName(e.target.value)
  }

  const handleChangePhoneNo = (e) => {
    setPhoneNo(e.target.value)
  }

  const handleEnter = async () => {
    if (validateEmail(email)) {
      await klavyioSignUp({
        email,
        signupID: data.SignUpId,
        source: 'Signup Footer',
        ...(firstName && { first_name: firstName }),
        ...(lastName && { last_name: lastName }),
        ...(phoneNo && { phone_number: phoneNo }),
      })
      trackSignUp({ method: email.includes('@') ? 'email' : 'phone' })
      setEmail('')
      setSent(true)
      onSubmit()
    }
  }
  const add = (event) => {
    if (event.keyCode === 13) {
      handleEnter()
    }
  }
  const validateEmail = (value) => {
    const regex =
      /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/ // eslint-disable-line
    if (value.length === 0) {
      setEmailValid(false)
      setErrorMessage('Please enter an email address.')
      return false
    } else if (!regex.test(value)) {
      setEmailValid(false)
      setErrorMessage('Please enter a valid email address.')
      return false
    } else if (!consent) {
      setErrorMessage('Please consent to the terms and conditions.')
      setEmailValid(false)
      return
    }
    setEmailValid(true)
    setErrorMessage()
    return true
  }

  return (
    <SectionContainerTop
      color={theme?.Background}
      HeightPercentage={data?.Body && 100}
    >
      <SectionCopy center={true}>
        <CopyWidthContainer>
          {
            Logo && (
              <LogoImage
                loading={'eager'}
                objectFit='contain'
                objectPosition='left'
                alt={Logo?.alternativeText || Logo?.name}
                image={withArtDirection(
                  getImage(Logo?.file?.childImageSharp?.gatsbyImageData),
                  [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(
                        Logo?.file?.childImageSharp?.gatsbyImageDataMobile ? Logo?.file?.childImageSharp?.gatsbyImageDataMobile : Logo?.file?.childImageSharp?.gatsbyImageDataMobile
                      ),
                    },
                  ]
                )}
              />
            )
          }
          <HeaderCopyHeading
            large={data?.includePhoneNo ? true : false}
            color={theme.Text}
          >
            {data.Heading}
          </HeaderCopyHeading>
          {data.Body && (
            <HeaderCopySubHeading
              color={theme.Text}
            >
              <span dangerouslySetInnerHTML={{ __html: data.Body }} />
            </HeaderCopySubHeading>
          )}
          <SignupContent>
            <EmailButtonContainer>
              {sent ? (
                <>
                  {' '}
                  <br /> Thanks for signing up{' '}
                </>
              ) : (
                <div style={{ display: 'block' }}>
                  {data?.includeName && (
                    <PersonalDetailsContainer>
                      <TextInputContainer>
                        <TextInput
                          value={firstName}
                          onKeyDown={add}
                          onChange={handleChangeFirstName}
                          placeholder={'First Name'}
                        />
                      </TextInputContainer>
                      <TextInputContainer>
                        <TextInput
                          value={lastName}
                          onKeyDown={add}
                          onChange={handleChangeLastName}
                          placeholder={'Last Name'}
                        />
                      </TextInputContainer>
                    </PersonalDetailsContainer>
                  )}
                  <TextInputContainer>
                    <TextInput
                      value={email}
                      onKeyDown={add}
                      onChange={handleChange}
                      placeholder={'Your email'}
                    />
                    <SignTransparentButton onClick={handleEnter}>
                      {onSubmit && 'Next'}
                      <img src={ChevronRight} alt={'ChevronRight'} />
                    </SignTransparentButton>
                  </TextInputContainer>
                  {data?.includePhoneNo && (
                    <>
                      <PersonalDetailsContainer>
                        <TextInputContainer>
                          <TextInput
                            value={phoneNo}
                            onKeyDown={add}
                            onChange={handleChangePhoneNo}
                            placeholder={'Phone No. (optional)'}
                          />
                        </TextInputContainer>
                      </PersonalDetailsContainer>
                    </>
                  )}
                </div>
              )}
            </EmailButtonContainer>
            {
              (!emailValid) && (
                <ErrorMsg>
                  {errorMessage}
                </ErrorMsg>
              )
            }
          </SignupContent>
          {
            data.ConsentText && data.ConsentText.length > 0 && (
              <ToggleContainer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 32 }}>
                <div className="Button" style={{ padding: 8 }}>
                  <Toggle id='consent' value={consent} setValue={setConsent} />
                </div>
                <div style={{ flexGrow: 1, fontSize: 12 }}>
                  <span dangerouslySetInnerHTML={{ __html: data.ConsentText }} />
                </div>
              </ToggleContainer>
            )
          }
        </CopyWidthContainer>
      </SectionCopy>
      <SectionCopy image={true}>
        {
          (data.Image?.isStatic || !data.Image) ? (
            <StaticHeaderImage src={data.Image?.url} />
          ) : (
            data.Image?.mime?.includes('video') ? (
              <HeaderVideo
                alt={data.Image?.alternativeText || data.Image?.name}
                src={data.Image?.file.publicURL}
                margin={data.Heading === "BUNDLE & SAVE" && 'custom-top'}
              />
            ) : (
              <HeaderImage
                alt={data.Image?.alternativeText || data.Image?.name}
                image={withArtDirection(
                  getImage(data.Image?.file?.childImageSharp?.gatsbyImageData),
                  [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(
                        data.Image?.file?.childImageSharp?.gatsbyImageDataMobile ? data.Image?.file?.childImageSharp?.gatsbyImageDataMobile : data.Image?.file?.childImageSharp?.gatsbyImageDataMobile
                      ),
                    },
                  ]
                )}
                style={{ objectFit: 'contain' }}
              />
            )
          )}
      </SectionCopy>
    </SectionContainerTop>
  )
}

export default SignupForm
